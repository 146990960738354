import React, { useEffect } from 'react';

const Video = () => {

  useEffect(() => {
    const videoButton = document.getElementById("videoButton01");
    videoButton.addEventListener("click", playPause);
    const videoPlayer = document.getElementById("videoPlayer01");
    const videoSource = document.getElementById("videoSource01");
    function playPause() { 
      if (videoSource.paused) {
        videoSource.play();
        videoPlayer.classList.add('is-playing');
      } else {
        videoSource.pause(); 
        videoPlayer.classList.remove('is-playing');
      }
    }    
  }, []);

  return (
    <>
      <section className="section is-full">
        <h1 className="title is-size-2 is-alternative is-400 f-h-center visible-desktop-false">
          Confira o vídeo completo da campanha
        </h1>
        <div className="v-space visible-desktop-false"></div>
        <div
          className="video-bg"
          style={{ backgroundImage: `url(${require('../../../images/backgrounds/video-bg.jpg')})` }}>
          <div id="videoPlayer01" className="video-container"> 
            <button id="videoButton01" className="video-button" />
            <span className="video-text-left visible-desktop-true">
              Confira o vídeo da campanha
            </span>
            <span className="video-logo-right visible-desktop-true">
              <figure
                className="image is-logo-text"
                style={{ backgroundImage: `url(${require('../../../images/logos/logo-atitudesdobem.svg')})` }}>
                <img src={require('../../../images/logos/logo-atitudesdobem.svg')} alt="Image Placeholder" />
              </figure>
            </span>
            <video id="videoSource01" className="video-source" controls loop>
              <source
                src="https://firebasestorage.googleapis.com/v0/b/mlw-firebase.appspot.com/o/assets%2Fatitudesdobem_teaser.mp4?alt=media&token=a24a4c50-b549-41fc-b306-f7d1b24361af"
                type="video/mp4"
              />
            </video>
          </div>
        </div>
      </section> 
    </>
  );
};

export default Video;