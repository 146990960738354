import React from 'react';
import { Link } from 'gatsby';

const Sections = () => {
  return (
    <>
      <div
        id="next"
        className="sections-bg"
        style={{ backgroundImage: `url(${require('../../../images/backgrounds/sections-group-bg.svg')})` }}>
        <section className="section is-compact">
          <div className="container">
            <div className="columns is-vcentered">
              <div className="column is-half-desktop">
                <div className="list-number">
                  <div className="list-number-child">
                    <span className="list-number-number">
                      1
                    </span>
                    <h1 className="title is-size-2">
                      Guarde com carinho a etiqueta da sua nova peça Malwee, Malwee Kids ou Malwee Liberta! 
                    </h1>
                    <div className="v-space-lg visible-desktop-true"></div>
                  </div>
                  <div className="list-number-child">
                    <p className="text is-size-3 mobile-behavior mobile-behavior">
                      Cada etiqueta registrada no site se transforma em uma doação da Malwee para uma das ONGs participantes. Quem escolhe para onde vai a contribuição é você!
                    </p>
                  </div>
                </div>
              </div>
              <div className="column is-half-desktop">
                <figure
                  className="image is-section-image"
                  style={{ backgroundImage: `url(${require('../../../images/pics/step-1.png')})` }}>
                  <img src={require('../../../images/pics/step-1.png')} alt="Image Placeholder" />
                </figure>
              </div>
            </div>
          </div>
        </section>
        <section className="section is-compact">
          <div className="container">
            <div className="columns is-vcentered">
              <div className="column is-half-desktop visible-desktop-true">
                <figure
                  className="image is-section-image"
                  style={{ backgroundImage: `url(${require('../../../images/pics/step-2.png')})` }}>
                  <img src={require('../../../images/pics/step-2.png')} alt="Image Placeholder" />
                </figure>
              </div>
              <div className="column is-half-desktop">
                <div className="list-number">
                  <div className="list-number-child">
                    <span className="list-number-number">
                      2
                    </span>
                    <h1 className="title is-size-2">
                      Conheça as ONGs participantes e escolha a causa que mais te representa.
                    </h1>
                    <div className="v-space-lg visible-desktop-true"></div>
                  </div>
                  <div className="list-number-child">
                    <p className="text is-size-3 mobile-behavior">
                      Você pode filtrar as ONGs para localizar em qual região elas atuam e o tipo de trabalho que desenvolvem com as crianças. 
                    </p>
                  </div>
                </div>
              </div>
              <div className="column is-half-desktop visible-desktop-false">
                <figure
                  className="image is-section-image"
                  style={{ backgroundImage: `url(${require('../../../images/pics/step-2.png')})` }}>
                  <img src={require('../../../images/pics/step-2.png')} alt="Image Placeholder" />
                </figure>
              </div>
            </div>
          </div>
        </section>
        <section className="section is-compact">
          <div className="container">
            <div className="columns is-vcentered">
              <div className="column is-half-desktop">
                <div className="list-number">
                  <div className="list-number-child">
                    <span className="list-number-number">
                      3
                    </span>
                    <h1 className="title is-size-2">
                      Registre a etiqueta e sua participação.
                    </h1>
                    <div className="v-space-lg visible-desktop-true"></div>
                  </div>
                  <div className="list-number-child">
                    <p className="text is-size-3 mobile-behavior">
                      Cadastre os 14 dígitos que se encontram abaixo do código de barras da etiqueta da sua roupa e preencha seus dados. Cada CPF pode cadastrar até duas peças para doação
                    </p>
                  </div>
                </div>
              </div>
              <div className="column is-half-desktop">
                <figure
                  className="image is-section-image"
                  style={{ backgroundImage: `url(${require('../../../images/pics/step-3.png')})` }}>
                  <img src={require('../../../images/pics/step-3.png')} alt="Image Placeholder" />
                </figure>
              </div>
            </div>
          </div>
        </section>
      </div>
      <section className="section has-title-overlaid">
        <span className="title-overlaid visible-desktop-true">
          Comece agora
        </span>
        <span className="title-overlaid visible-desktop-false">
          Comece <br /> agora
        </span>
        <div className="container">
          <div className="wrapper is-flex">
            <h1 className="title is-xxlarge-m is-uppercase is-alternative is-700">
              Comece agora
            </h1>
            <Link to="instituicoes" className="button">
              <span>Escolha a ONG</span>
            </Link>
          </div>
        </div>
      </section>
    </>
  );
};

export default Sections;