import React, { useEffect, useState } from 'react';
import { Link } from 'gatsby';

import useModal from '../../hooks/useModal';

const Ambassadors = () => {

  const [Modal, toggleModal] = useModal();
  const [modalContent, updateContent] = useState(null);

  useEffect(() => {
    updateContent(
      <>
        <div className="yt-video">
          <iframe width="560" height="315" src="https://www.youtube.com/embed/AKz5W53vuX4" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </div>
      </>
    );
  }, []);

  return (
    <>

      <Modal>
        {modalContent}
        <button
          onClick={toggleModal}
          className="modal-close is-large"
          aria-label="close">
          Fechar
        </button>
      </Modal>

      <section
        id="numeros"
        className="section is-dark has-dots"
        style={{ backgroundImage: `url(${require('../../../images/backgrounds/section-dots-light-bg.svg')})` }}>
        <div className="container is-large">

          <header className="section-header">
            <h3 className="title is-white is-700">Acompanhe nossos embaixadores</h3>
          </header>

          <div className="columns is-desktop">
            <div className="column is-one-fifth-desktop">
              <div className="polaroid">
                <figure
                  className="polaroid-picture"
                  style={{ backgroundImage: `url(${require('../../../images/pics/mia-mello.png')})` }}>
                  <img src={require('../../../images/pics/mia-mello.png')} alt="Image Placeholder" />
                </figure>
                <div className="polaroid-content">
                  <p className="text is-700 is-white">
                    Mia Mello
                  </p>
                  <p className="text is-size-6 is-white">
                    @miamello
                  </p>
                </div>
              </div>
            </div>
            <div className="column is-one-fifth-desktop">
              <div className="polaroid">
                <figure
                  className="polaroid-picture"
                  style={{ backgroundImage: `url(${require('../../../images/pics/celso-portiolli.jpg')})` }}>
                  <img src={require('../../../images/pics/celso-portiolli.jpg')} alt="Image Placeholder" />
                </figure>
                <div className="polaroid-content">
                  <p className="text is-700 is-white">
                    Celso Portiolli
                  </p>
                  <p className="text is-size-6 is-white">
                    @celsoportiolli
                  </p>
                </div>
              </div>
            </div>
            <div className="column is-one-fifth-desktop">
              <div className="polaroid">
                <figure
                  className="polaroid-picture"
                  style={{ backgroundImage: `url(${require('../../../images/pics/dudu-azevedo.jpg')})` }}>
                  <img src={require('../../../images/pics/dudu-azevedo.jpg')} alt="Image Placeholder" />
                </figure>
                <div className="polaroid-content">
                  <p className="text is-700 is-white">
                    Dudu Azevedo
                  </p>
                  <p className="text is-size-6 is-white">
                    @duduazevedo
                  </p>
                </div>
              </div>
            </div>
            <div className="column is-one-fifth-desktop">
              <div className="polaroid">
                <figure
                  className="polaroid-picture"
                  style={{ backgroundImage: `url(${require('../../../images/pics/ricardo-pereira.png')})` }}>
                  <img src={require('../../../images/pics/ricardo-pereira.png')} alt="Image Placeholder" />
                </figure>
                <div className="polaroid-content">
                  <p className="text is-700 is-white">
                    Ricardo Pereira
                  </p>
                  <p className="text is-size-6 is-white">
                    @ricardotpereira
                  </p>
                </div>
              </div>
            </div>
            <div className="column is-one-fifth-desktop">
              <div className="polaroid">
                <figure
                  className="polaroid-picture"
                  style={{ backgroundImage: `url(${require('../../../images/pics/dani-suzuki.jpg')})` }}>
                  <img src={require('../../../images/pics/dani-suzuki.jpg')} alt="Image Placeholder" />
                </figure>
                <div className="polaroid-content">
                  <p className="text is-700 is-white">
                    Dani Suzuki
                  </p>
                  <p className="text is-size-6 is-white">
                    @danisuzuki
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

    </>
  );
};

export default Ambassadors;