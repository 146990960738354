import React, { useEffect } from 'react';
import { Link } from 'gatsby';

import useInstitutions from '../../hooks/useInstitutions';

import Card from '../institutions/Card';

const Institutions = () => {

  const [{ fetch }, { response, status }] = useInstitutions({ limit: 3 });
  
  useEffect(() => {
    fetch();
  }, []);

  const cards = response.map((item) => {

    return (
      <div className="column is-one-third-desktop" key={item.id}>
        <Card {...item} />
      </div>
    );

  });

  return (
    <>

      <section
        id="instituicoes"
        className="section has-dots"
        style={{ backgroundImage: `url(${require('../../../images/backgrounds/section-dots-bg.svg')})` }}>

        <div className="container">

          <header className="section-header">
            <h1 className="title is-xxlarge">
              Instituições
            </h1>
            <div className="v-space-lg visible-desktop-true"></div>
            <div className="v-space visible-desktop-false"></div>
            <p className="text">
              Todas as ONGs participantes da Campanha Atitudes do Bem foram cuidadosamente selecionadas pela Malwee e trabalham com diferentes formas de apoio à criança.  
            </p>
          </header>

          <div className="columns">
            {cards}
          </div>

          <footer className="section-footer">
            <Link to="instituicoes" className="button is-large">
              <span>Conheça as ONGs</span>
            </Link>
          </footer>

        </div>

      </section>
    </>
  );
};

export default Institutions;