import React from 'react';
import { Link } from 'gatsby';

const Hero = () => {
  return (
    <>

      <section
        className="hero is-medium visible-desktop-true"
        style={{ backgroundImage: `url(${require('../../../images/backgrounds/hero-bg.jpg')})` }}>
        <div className="hero-body">
          <div className="container">
            <div className="columns is-gapless">
              <div className="column is-half-desktop">
                <h1>
                  <figure
                    className="image is-logo-text-lg"
                    style={{ backgroundImage: `url(${require('../../../images/logos/logo-atitudesdobem.svg')})` }}>
                    <img src={require('../../../images/logos/logo-atitudesdobem.svg')} alt="Atitudes do Bem" />
                  </figure>
                </h1>
                <div className="v-space"></div>
                <p className="text is-white is-size-4">
                  <strong>1 peça comprada e cadastrada no site = 1 doação da Malwee para uma ONG escolhida por você</strong>
                </p>
                <div className="v-space"></div>
                <p className="text is-white is-size-4">
                  Neste fim de ano, junte-se a nós na maior campanha de doação já realizada pela Malwee para apoiar diversas ONGs que trabalham com apoio a crianças. 
                </p>
                <div className="v-space"></div>
                <p className="text is-white is-size-4">
                  O Instituto Malwee vai doar o valor equivalente a uma camiseta para cada peça comprada e cadastrada no site das coleções Malwee: Alto Verão, Celebration, Malwee Kids e Malwee Liberta. 
                </p>
                <div className="v-space"></div>
                <p className="text is-white is-size-4">
                  <strong>
                    Você escolhe quais organizações vamos apoiar!
                  </strong>
                </p>
                <div className="v-space-lg"></div>
                <Link to="instituicoes" className="button is-outlined is-white">
                  <span>Escolha a ONG</span>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section> 

      <section
        className="hero is-mobile visible-desktop-false"
        style={{ backgroundImage: `url(${require('../../../images/backgrounds/hero-bg.jpg')})` }}>
        <div className="hero-body">
          <div className="container">
            <h1>
              <figure
                className="image is-logo-text-lg"
                style={{ backgroundImage: `url(${require('../../../images/logos/logo-atitudesdobem.svg')})` }}>
                <img src={require('../../../images/logos/logo-atitudesdobem.svg')} alt="Atitudes do Bem" />
              </figure>
            </h1>
            <div className="v-space"></div>
            <p className="text is-white is-size-4">
              Faça parte dessa corrente que esta mudando a história de muitas crianças
            </p>
            <div className="v-space-lg"></div>
            <Link to="instituicoes" className="button is-outlined is-white">
              <span>Quero fazer o bem</span>
            </Link>
            <div className="v-space"></div>
            <a href="#next" className="button is-down">
              <span>Como funciona?</span>
              <svg width="16" height="10" viewBox="0 0 16 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1.88667 0L8 6.11333L14.1133 0L16 1.88667L8 9.88667L0 1.88667L1.88667 0Z" fill="white"/>
              </svg>
            </a>
          </div>
        </div>
      </section> 

    </>
  );
};

export default Hero;